// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/simonolsen/Work/projects/img-sales-uk/wwwgatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/Users/simonolsen/Work/projects/img-sales-uk/wwwgatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-thank-you-for-contacting-us-js": () => import("/Users/simonolsen/Work/projects/img-sales-uk/wwwgatsby/src/pages/thank-you-for-contacting-us.js" /* webpackChunkName: "component---src-pages-thank-you-for-contacting-us-js" */),
  "component---src-pages-uptime-js": () => import("/Users/simonolsen/Work/projects/img-sales-uk/wwwgatsby/src/pages/uptime.js" /* webpackChunkName: "component---src-pages-uptime-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/simonolsen/Work/projects/img-sales-uk/wwwgatsby/.cache/data.json")

